.release-card {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  width: min(100%, 80vh);
  border: 1px solid black;
}

.release-card-image {
  width: 100%;
  object-fit: cover;
}

.listen-button {
  margin: 0;
  width: 100%;
}

.black {
  background-color: black;
  color: white;
}