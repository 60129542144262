.back-home-button {
  display: flex;
  align-items: center;

  background: none;
	color: inherit;
	border: none;
	outline: inherit;
  padding: 10px;
  cursor: pointer;
  backdrop-filter: blur(6px);
}

.back-home-button:hover {
  background-color: #ffffff2a !important;
}

.back-home-button-text {
  display: flex;
  align-items: center;
  margin-left: 5px;
}