#error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: center;

  background-color: #000000dc;
  color: rgb(219, 210, 210);
}

.credit-error {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 50px;
  font-style: italic;
}

.button-bar {
  position: fixed;
  display: flex;
  overflow-x: auto;
}

.error-button-bar {
  top: 0;
  left: 0;
  padding: 25px;
  width: 100%;
  justify-content: space-between !important;
}