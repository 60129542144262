.new-release-container {
  top: 0;
  display: flex;
  height: 100vh;
  min-height: 300px;
  width: 100%;
  z-index: 11;

  padding: 50px;
  flex-direction: row !important;
  justify-content: flex-end !important;
  align-items: center;
}

#current-release {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  font-family: 'Fjalla One', sans-serif;
  font-size: calc(1vw + 20px);
  text-align: right;
  width: fit-content;
  height: fit-content;
  padding: 30px;
  color: white;
  border: 4px white solid;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  box-shadow: rgba(0, 0, 0, .8) 10px 10px 25px 5px;
}