.experience-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px;
  z-index: 8;
  background: #62bb8e;

  position: sticky;
  padding-bottom: 20px;
}

.exp-title-container {
  flex: 1;
  min-width: 350px;
  padding: 40px;
}

.exp-title {
  color: white;
  width: fit-content;
  text-align: center;
  margin: auto;
  margin-top: 50px;
}

.timeline {
  width: 100%;
  padding: 10px;
  min-width: 300px;
  position: relative;
  margin: auto;
  flex: 2;
}

@media only screen and (min-width: 1000px) {
  .exp-title {
    text-align: end;
  }

  .timeline, .exp-title-container {
    padding: 30px;
  }

  .timeline::before {
    content: "";
    position: absolute;
    height: calc(100% - 60px);
    width: 2px;
    left: 50%;
    top: 30px;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .timeline .item:nth-child(odd) {
    float: left;
    clear: right;
  }

  .timeline .item:nth-child(even) {
    float: right;
    clear: left;
  }

  .timeline .item {
    margin-bottom: 40px;
    width: calc(50% - 40px);
  }

  .timeline .item::after, .timeline::after {
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    background-color: black;
    border-radius: 50%;
    transform: translate(-6px, -170%);
    left: 50%;
  }

  .timeline::after {
    bottom: 0;
  }
}