.hero-container {
  background: linear-gradient(0deg, #ffa58e, #ffc0b4, #acd3ff, #93c5fe, #8ac0ff, #75b5ff, #57a5ff);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
  height: 100svh;
}

.text-right {
  text-align: end;
  padding: 20px;
  padding-top: 50px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
} 

.arrow-direction {
  position: relative;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 32px;
  font-size: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 5px;

  color: #ffffff;
  border-radius: 5px 0 0 0;
  box-shadow: #0000005f 0px 0px 5px 1px;
}

.arrow-icon {
  animation: fly-up-down 1s ease-in-out infinite;
}

@keyframes fly-up-down {
  0% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(-3px);
  }
}