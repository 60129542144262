:root {
  --social-link-height: 50px;
  --hero-bottom-offset: 100vh;
  --doodle-background-size: 350px;
}

.music-container {
  overflow: clip;
}

.text-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.music-button-bar {
  top: 0;
  left: 0;
  padding: 15px;
  color: white;
  justify-content: flex-start !important;
  transition: background 0.2s;
  overflow: hidden;
  z-index: 9999;
}

.listen-button {
  background: #fff;
  color: black;
  border: none;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  font-size: calc(.8vw + 13px);
  font-family: 'Fjalla One', sans-serif;
  margin-top: 10px;
}