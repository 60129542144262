.edu-container {
  background: #ffa58e;
  height: 100svh;
  z-index: 6;
}

.floating {
  animation: float 2s infinite ease-in-out;
}

.text {
  text-align: start;
}