.card {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 20px;
  transition: box-shadow 0.15s;
  transition: background 0.25s;
  transition: color 0.1s;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.card:hover {
  background: rgba(0, 0, 0, 1);
  color: white;
}

.heading {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.heading img {
  width: 70px
}

.logo-container {
  display: flex;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2;
}

.title {
  font-weight: 700;
  font-size: calc(17px + 0.8vw);
}

.subtitle {
  font-weight: 500;
  font-size: calc(12px + 0.8vw);
}

.date {
  font-weight: 300;
  font-size: calc(12px + 0.8vw);
  font-style: italic;
}

.description {
  margin: 15px 0 15px 0;
  font-weight: 300;
  font-size: calc(12px + 0.8vw);
}

.code-button {
  font-size: 30px;
  height: fit-content;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background: none;
  border: none;
  transition: background 0.05s;
  cursor: pointer;
  padding: 5px;
}

.code-button:hover {
  background:rgba(255, 255, 255, 0.3);
}

.card:hover button {
  color: white;
}

.tech-stack-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-auto-columns: max-content;
  grid-auto-flow: dense;
  grid-gap: 10px;
}

.tech-item {
  height: calc(30px + 0.4vw);
  font-size: calc(14px + 0.4vw);
  background-color: white;
  color: black;
  width: 100%;
  text-align: center;
  padding: 5px;
  font-weight: 400;
  border-radius: 20px;
  margin: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
              rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.tech-item > * {
  height: 100%;
 }