:root {
  --social-link-height: 50px;
}

* {
  transition: font-size 40ms;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Lato, Roboto, sans-serif;
  scroll-behavior: smooth;
}

.content-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  scroll-snap-type: y proximity;
  scroll-margin-top: 10vh;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 15vh 0 15vh 0;
}

.text-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.image-cropper {
  box-shadow: rgba(0, 0, 0, 0.66) 0px 10px 15px 2px;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  overflow: hidden;
}

.float-hover {
  transition: all .2s, transform .3s, box-shadow .3s;
}

.float-hover:hover {
  transform: translateY(-20px);
  box-shadow: rgba(0, 0, 0, 0.46) 0px 30px 30px 2px;
}

@media only screen and (max-width: 1000px) {
  .content-container {
    min-height: auto;
  }

  .links {
    flex-direction: row;
  }

  .text-wrapper {
    flex: 0;
  }

  .text-wrapper > * {
    text-align: center;
  }

  .text-wrapper {
    justify-content: flex-start;
  }

  .content {
    flex-direction: column;
    padding-top: 6vh;
    padding-bottom: 5vh;
  }

  .image-cropper {
    width: 40vw;
    height: 40vw;
    min-width: 200px;
    min-height: 200px;
  } 

  .image-container {
    margin: 30px;
  }
}

@keyframes gradient {
	0% {
		background-position: 5% 80%;
	}
	50% {
		background-position: 100% 0%;
	}
	100% {
		background-position: 5% 80%;
	}
}

@keyframes float {
  0% {
    transform: translateY(0px);
    box-shadow: rgba(0, 0, 0, 0.66) 0px 10px 15px 2px;
  }
  50% {
    transform: translateY(-20px);
    box-shadow: rgba(0, 0, 0, 0.46) 0px 40px 40px 2px;
  }
  100% {
    transform: translateY(0px);
    box-shadow: rgba(0, 0, 0, 0.66) 0px 10px 15px 2px;
  }
}

.button-bar {
  position: sticky;
  width: fit-content!;
  display: flex;
  z-index: 9999;
}

.home-button-bar {
  left: 15px;
  top: 15px;
}
