.links {
  /* left: 0; */
  bottom: 0px;
  padding: 5px;
  padding-right: 10px;
  z-index: 1000;
  transition: filter 0.5s;
}

.centered-links {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.left-links {
  left: 0;
}

.right-links {
  right: 0;
}

.linkedin-button {
  background: url(../../assets/linkedin_icon.svg);
}

.github-button {
  background: url(../../assets/github_icon.svg);
}

.gitlab-button {
  background: url(../../assets/gitlab_icon.svg);
}

.resume-button {
  background: url(../../assets/resume_icon.png);
}

.facebook-button {
  background: url(../../assets/facebook_icon.png);
}

.instagram-button {
  background: url(../../assets/instagram_icon.png);
}

.youtube-button {
  background: url(../../assets/youtube_icon.png);
}

.spotify-button {
  background: url(../../assets/spotify_icon.png);
}

.soundcloud-button {
  background: url(../../assets/soundcloud_icon.png);
}

.icon-button {
  width: 30px;
  height: 35px;
  border: none;
  cursor: pointer;
  margin: 5px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  transition: border 0.1s;
}

.music-icon-button {
  /* margin: 0; */
  width: 22px;
  height: 30px;
}

.icon-button:hover {
  opacity: 0.9;
  border-bottom: 3px dotted black;
}

.music-icon-button:hover {
  opacity: 0.8;
  border-bottom: none;
}

.white-icons > * {
  filter: brightness(0) invert(1) drop-shadow(0 0 6px #000);
}