.songs-container {
  position: relative;
  padding: 10vw;

  background: url(../../../../assets/backgrounds/music_white_background_doodle.png);
  background-size: calc(var(--doodle-background-size) / 0.8);
  background-position: center;
  border: 2px dashed black;
  z-index: 20;
}

.songs-section-title {
  width: 100%;
  color: black;
  top: 100px;
  z-index: 16;
  font-family: 'Rubik Dirt', system-ui;
  font-size: calc(4vw + 30px);
  text-align: right;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.songs-content {
  width: 100%;
  margin-top: 5%;
}

.half-divider {
  right: 0;
  height: 2.7px;
  background: black;
  animation: divider-length 3s infinite;
}

@keyframes divider-length {
	0% {
    width: 100%;
	}
	50% {
    width: 100px;
	}
	100% {
    width: 100%;
	}
}
