.music-hero-container {
  display: block !important;
  height: 445vh;
  width: 100%;
  background: #00000055;
  overflow: clip;
}

.big-portrait-container {
  height: 100%;
  width: 100%;
}

.big-portrait-cropper {
  height: 100lvh;
  position: sticky;
  top: 0;
}

.music-font {
  font-family: 'Rubik Glitch', system-ui;
  font-style: italic;
  color: white;
}

.music-title {
  padding: 5%;
  top: 60vh;
  font-size: calc(4vw + 30px);
  position: fixed;
  left: 0;
  z-index: 9;
  color: white;
  text-shadow: 0 0 10px #000;
}

.second-page {
  font-family: 'Rubik Doodle Shadow', system-ui;
  text-align: right;
  left: auto;
  right: 10vw;
  padding: 10px;
  width: calc(20vw + 90px);
  font-size: calc(2vw + 20px);
  z-index: 9;
}

#its-word {
  font-size: calc(1vw + 20px);
  padding-left: calc(1vw + 10px);
}

#in-music-word {
  font-size: calc(1vw + 10px);
  padding-left: 10px;
}

#name {
  font-family: 'Rubik Dirt', system-ui;
  padding-left: 2px;
}

.overlay-container {
  /* filter: invert(100%); */
  position: sticky;
  left: 0;
  top: 0;
  overflow: clip;
  z-index: 9;
}

.doodles-overlay {
  position: absolute;
  top: 0;
  width: 100%;

  height: calc(var(--hero-bottom-offset));
  background: url(../../../../assets/backgrounds/doodle_transparent.png);
  background-size: calc(var(--doodle-background-size) / 0.8);
  background-repeat: repeat;
  background-position-x: center;
  z-index: 2;

  overflow: clip;
  /* filter: invert(100%); */
}

.new-release-overlay-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}


.new-release-overlay {
  position: relative;
  flex-shrink: 0;
  --size: max(100vh, 100vw);
  width: var(--size);
  height: var(--size);
  z-index: 10;

  border-radius: 10%;
  border: 10px solid white;

  -webkit-box-shadow: 0px 0px 45px 10px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 0px 45px 10px rgba(0,0,0,0.4);
  box-shadow: 0px 0px 45px 10px rgba(0,0,0,0.4);
}

.bw-background-container {
  position: absolute;
  top: -100lvh;
  width: 100%;
  height: 200vh;
  /* filter: invert(100%); */
}

.bw-background {
  position: sticky;
  top: 0;
  height: 100lvh;
}

.full-page-offset {
  position: absolute;
  top: calc(100vh + 30px);
  height: 100vh;
  width: 100%;
}

.new-release-dummy-offset {
  position: absolute;
  bottom: 0;
  height: 100vh;
}

.arrow-direction {
  position: fixed;
  box-shadow: none;
  left: 50%;
}